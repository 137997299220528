import React from "react"
import {Routes, Route} from "react-router-dom"
import Link from "./uLink"
import HelpTwo from "./uHelpEx"
import HelpOne from "./uHelpM"
import './App.css'
import {urlAlert} from "api-methods"

function App() {

    let pn = (document.location.pathname + "")
    if (pn.replace(/[?=&_A-Za-z0-9./:]+/g, "") !== "") {
        if (document.location.pathname !== '/instruction/expired_index%09') {
            urlAlert('char').then()
        }
    } else if ((pn.length > 8 && !!(pn.split('_')[0]).split('ru/')[1] && !!(pn.split('_')[0]).split('ru/')[1].length > 8) && pn.substr(0, 13) !== '/instruction/') {
        urlAlert('url').then()
    }

    return (
        <Routes>
            <Route path="/instruction/kav" element={<HelpOne/>}/>
            <Route path="/instruction/kis" element={<HelpOne/>}/>
            <Route path="/instruction/kids" element={<HelpOne/>}/>
            <Route path="/instruction/drweb" element={<HelpOne/>}/>
            <Route path="/instruction/kis_mobile" element={<HelpOne/>}/>
            <Route path="/instruction/nod32" element={<HelpOne/>}/>
            <Route path="/instruction/eset_mobile" element={<HelpOne/>}/>
            <Route path="/instruction/*" element={((pn.indexOf('/instruction/expired_index') > -1) ? <HelpTwo/> : <div className="notFound">404</div>)}/>
            <Route path="/*" element={<Link />} />
        </Routes>
    )
}

export default App
